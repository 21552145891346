var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as yup from 'yup';
import { useField } from 'vee-validate';
import i18n from '@/i18n';
import { defineComponent, ref, inject, computed, onBeforeMount } from 'vue';
import { IonContent, IonCard, IonCardContent, IonCardHeader, IonPage, IonInput, IonItem, IonLabel, IonButton, IonCheckbox, IonFooter, } from '@ionic/vue';
import { useRouter, useRoute } from 'vue-router';
import RegisterAdvantages from '@/components/RegisterAdvantages.vue';
import DesktopRegisterAdvantages from '@/components/DesktopRegisterAdvantages.vue';
import TermsAndConditions from '@/components/termsAndConditions.vue';
import Swal from 'sweetalert2';
var componentName = 'Register';
var components = {
    IonContent: IonContent,
    IonCard: IonCard,
    IonCardContent: IonCardContent,
    IonCardHeader: IonCardHeader,
    IonPage: IonPage,
    IonInput: IonInput,
    IonItem: IonItem,
    IonLabel: IonLabel,
    IonButton: IonButton,
    IonCheckbox: IonCheckbox,
    IonFooter: IonFooter,
    RegisterAdvantages: RegisterAdvantages,
    DesktopRegisterAdvantages: DesktopRegisterAdvantages,
    TermsAndConditions: TermsAndConditions
};
var setup = function () {
    var success = ref(false);
    var showTerms = ref(false);
    var error = ref(false);
    var errormsg = ref('');
    // const privacy: Ref<boolean> = ref(false)
    var tos = ref(false);
    var prefix = ref(false);
    var authStore = inject('authState');
    var validPrefixList = computed(function () { return authStore.state.validPrefixList; });
    var signUp = computed(function () { return authStore.state.signUp; });
    var showRegisterAdvantages = ref(false);
    var loading = ref(false);
    onBeforeMount(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, authStore.obtainCountry()];
                case 1:
                    _a.sent();
                    setPrefixDefault();
                    return [2 /*return*/];
            }
        });
    }); });
    // TODO: Extract validators to their own file
    var _a = useField('name', yup.string().required(i18n.global.t('FIELD_REQUIRED'))), nameError = _a.errorMessage, name = _a.value;
    var _b = useField('surname', yup.string().required(i18n.global.t('FIELD_REQUIRED'))), surnameError = _b.errorMessage, surname = _b.value;
    var _c = useField('email', yup
        .string()
        .email(i18n.global.t('WRONG_EMAIL_FORMAT'))
        .required(i18n.global.t('FIELD_REQUIRED'))), mailError = _c.errorMessage, email = _c.value;
    var _d = useField('password', yup
        .string()
        .min(6, i18n.global.t('WRONG_PASSWORD_FORMAT'))
        .required(i18n.global.t('FIELD_REQUIRED'))), passwordError = _d.errorMessage, password = _d.value;
    var _e = useField('phone', yup
        .string()
        .required(i18n.global.t('FIELD_REQUIRED'))
        .matches(/^[0-9]+$/, i18n.global.t('WRONG_PHONE_FROMAT'))
        .min(9, i18n.global.t('WRONG_PHONE_FROMAT'))
        .max(9, i18n.global.t('WRONG_PHONE_FROMAT'))), phoneError = _e.errorMessage, phone = _e.value;
    var formValid = computed(function () {
        return !!name.value &&
            !nameError.value &&
            !!surname.value &&
            !surnameError.value &&
            !!email.value &&
            !mailError.value &&
            !!password.value &&
            !passwordError.value &&
            !!phone.value &&
            !phoneError.value &&
            tos.value;
    }
    // privacy.value &&
    );
    var router = useRouter();
    var route = useRoute();
    var setPrefixDefault = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            prefix.value = authStore.state.userData.mobilePrefix;
            return [2 /*return*/];
        });
    }); };
    var handleShowTerms = function () {
        showTerms.value = false;
    };
    var register = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, signedUp, payload, loggedIn, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    if (!formValid.value)
                        return [2 /*return*/];
                    success.value = false;
                    error.value = false;
                    errormsg.value = '';
                    loading.value = true;
                    data = {
                        email: email.value,
                        password: password.value,
                        name: name.value,
                        surname1: surname.value,
                        residenceCountry: authStore.state.userData.residenceCountry,
                        language: navigator.language.split("-")[0],
                        mobile: phone.value,
                        // TODO: Make the following properties' values dynamic
                        invitationCode: '',
                        referralCode: '',
                        invitedBy: '',
                        externalUserId: '',
                        prefix: prefix.value,
                        device: navigator.userAgent
                    };
                    if (route.query.invitationCode != undefined) {
                        data.invitationCode = route.query.invitationCode;
                    }
                    if (route.query.referral != undefined) {
                        data.referralCode = route.query.referral;
                    }
                    if (route.query.invitedBy != undefined) {
                        data.invitedBy = route.query.invitedBy;
                    }
                    if (route.query.user_id != undefined) {
                        data.externalUserId = route.query.user_id;
                    }
                    if (route.query.tduid != undefined) { // user id for Tradedoubler
                        authStore.state.tduid = route.query.tduid;
                    }
                    return [4 /*yield*/, authStore.signUp(data)];
                case 1:
                    signedUp = _a.sent();
                    if (signedUp.showError) {
                        console.log('error singingUp');
                        console.log(signedUp.errorMessage);
                        Swal.fire({
                            icon: 'error',
                            title: signedUp.errorMessage,
                        });
                        return [2 /*return*/];
                    }
                    payload = {
                        email: email.value,
                        password: password.value,
                        appVersion: ''
                    };
                    return [4 /*yield*/, authStore.logUserIn(payload)];
                case 2:
                    loggedIn = _a.sent();
                    if (!loggedIn.success) {
                        Swal.fire({
                            icon: 'error',
                            title: loggedIn.message,
                        });
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    loading.value = false;
                    return [3 /*break*/, 5];
                case 4:
                    router.push({ name: 'how', replace: true });
                    loading.value = false;
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return {
        name: name,
        nameError: nameError,
        surname: surname,
        surnameError: surnameError,
        email: email,
        mailError: mailError,
        password: password,
        passwordError: passwordError,
        phone: phone,
        prefix: prefix,
        phoneError: phoneError,
        // privacy,
        tos: tos,
        error: error,
        errormsg: errormsg,
        formValid: formValid,
        register: register,
        signUp: signUp,
        showRegisterAdvantages: showRegisterAdvantages,
        validPrefixList: validPrefixList,
        loading: loading,
        showTerms: showTerms,
        handleShowTerms: handleShowTerms,
    };
};
export default defineComponent({ name: componentName, components: components, setup: setup });
